import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "gatsby-plugin-react-i18next"

function fetchReducer(_state, { type, response, error }) {
  switch (type) {
    case "fetching": {
      return { error: null, response: null, pending: true, success: false }
    }
    case "success": {
      return { error: null, response, pending: false, success: true }
    }
    case "error": {
      return { error, response: null, pending: false, success: false }
    }
    default:
      throw new Error(`Unsupported type: ${type}`)
  }
}

function useFetch({ url, body }) {
  const [state, dispatch] = React.useReducer(fetchReducer, {
    error: null,
    response: null,
    pending: false,
    success: false,
  })
  const bodyString = JSON.stringify(body)

  React.useEffect(() => {
    if (url && bodyString) {
      dispatch({ type: "fetching" })
      fetch(url, {
        method: "post",
        body: bodyString,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(r => r.json())
        .then(
          response => dispatch({ type: "success", response }),
          error => dispatch({ type: "error", error })
        )
    }
  }, [url, bodyString])

  return state
}

function PostSubmissionMessage() {
  return (
    <div className="text-center">
      <div className="svg-box">
        <svg className="circular orange-stroke">
          <circle
            className="path"
            cx="75"
            cy="75"
            r="50"
            fill="none"
            strokeWidth="5"
            strokeMiterlimit="10"
          />
        </svg>
        <svg className="checkmark orange-stroke">
          <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
            <path
              className="checkmark__check"
              fill="none"
              d="M616.306,283.025L634.087,300.805L673.361,261.53"
            />
          </g>
        </svg>
      </div>

      <h3>You've subscribed!</h3>
    </div>
  )
}

function Subscribe() {
  const { t } = useTranslation()
  const [values, setValues] = useState()
  const { handleSubmit, register, errors } = useForm()
  const { pending, response, success, error } = useFetch({
    url: `https://app.way.live/api/leads/d8822aa7-f724-4e19-a31b-4b7bc0c33ed6/pages/wBH2issJO/capture`,
    body: values,
  })

  return (
    <div className="footer-newsletter-box">
      {!success && (
        <div>
          <span className="footer-title-sm">
            {t("Subscribe to our newsletter")}
          </span>
          <p>
            {t(
              "Subscribe to get lastest offers, news and events announcements. No spam in your inbox."
            )}
          </p>
          <form onSubmit={handleSubmit(data => setValues({ data }))}>
            <div className="form-group">
              <input
                name="email"
                className="form-control"
                placeholder={t("Enter your email address")}
                ref={register({
                  required: t("Required"),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t("Invalid email address"),
                  },
                })}
              />
              <button className="submit-btn" disabled={pending}>
                <i className="icon icon-tail-right" />
              </button>
            </div>
            {errors.email && (
              <span className="text-danger">{errors.email.message}</span>
            )}
            {error && (
              <span className="text-danger">{t("Something went wrong!")}</span>
            )}
          </form>
        </div>
      )}

      {success && <PostSubmissionMessage response={response} />}
    </div>
  )
}

export default Subscribe
