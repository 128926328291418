import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import heroImage from "../assets/images/hero-img.png"
import config from "../../config/website"

function Hero({ image = heroImage }) {
  const { t } = useTranslation()
  return (
    <section className="hero-area">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-6 col-xl-5 order-2 order-md-1">
            <div className="hero-content">
              <h1>{t("Build Websites or Landing Pages in Minutes")}</h1>
              <p>
                {t(
                  "No coding required. Trusted by 100+ founders, hobbyists, and marketing teams."
                )}
              </p>
              <div className="hero-btn">
                <a href={config.onboardingUrl} className="btn btn-hero">
                  {t("Start your 14-day free trial")}
                </a>
              </div>
            </div>
          </div>

          <div className="col-md-0 col-lg-6 col-xl-7 order-1 order-md-2">
            <div className="shape hero-image">
              <img src={image} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
