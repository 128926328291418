import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import About from "../components/about"
import GetStarted from "../components/get-started"
import Features from "../components/features"
import Pricing from "../components/pricing"

const IndexPage = () => (
  <Layout>
    <SEO />
    <Hero />
    <About />
    <Features />
    <Pricing />
    <GetStarted />
  </Layout>
)

export default IndexPage
