import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"

function Features() {
  const { t } = useTranslation()
  return (
    <section className="feature-section" id="features">
      <div className="container position-relative">
        <div className="row shape-bg">
          <div className="col-md-6 col-lg-5 offset-lg-1 col-xl-5 offset-xl-1 mb--30">
            <div className="feature-widget">
              <div className="widget-icon">
                <i className="icon icon-edit-72"></i>
              </div>
              <div className="content">
                <h5>{t("Easy to Use")}</h5>
                <p>
                  {t(
                    "Templates and blocks ready to use for you to get better results. Tested and recomended by our users."
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-5 col-xl-5 mb--30">
            <div className="feature-widget">
              <div className="widget-icon">
                <i className="icon icon-layers-3"></i>
              </div>
              <div className="content">
                <h5>{t("Quick Setup")}</h5>
                <p>
                  {t(
                    "It takes less than 30 seconds to publish a landing page and start to get results."
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-5 offset-lg-1 col-xl-5 offset-xl-1 mb--30">
            <div className="feature-widget">
              <div className="widget-icon">
                <i className="icon icon-tablet-mobile"></i>
              </div>
              <div className="content">
                <h5>{t("Responsive Design")}</h5>
                <p>
                  {t(
                    "Way makes every element and section mobile ready. You can focus in your business and strategies."
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-5 col-xl-5 mb--30">
            <div className="feature-widget">
              <div className="widget-icon">
                <i className="icon icon-book-open-2"></i>
              </div>
              <div className="content">
                <h5>{t("Easy Export")}</h5>
                <p>
                  {t("You can export your leads with a click. Just like that.")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Features
