import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import ctaShapeLeft from "../assets/images/cta-shape1.svg"
import ctaShapeRight from "../assets/images/cta-shape2.svg"
import config from "../../config/website"

function GetStarted() {
  const { t } = useTranslation()
  return (
    <section className="cta-section" id="contact">
      <div className="container cta-shapes-wrapper">
        <div className="shape cta-shapes">
          <div className="shape-inner-1">
            <img src={ctaShapeLeft} alt="" />
          </div>
          <div className="shape-inner-2">
            <img src={ctaShapeRight} alt="" />
          </div>
        </div>
        <div className="cta-content-wrapper">
          <h2>{t("Ready to get started?")}</h2>
          <a href={config.onboardingUrl} className="btn cta-btn">
            {t("Sign Up")}
          </a>
        </div>
      </div>
    </section>
  )
}

export default GetStarted
