import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import config from "../../config/website"
import logo from "../assets/images/main-logo.png"

function Header() {
  const { t } = useTranslation()
  return (
    <header className="site-header">
      <div className="container">
        <div className="row text-center justify-content-center align-items-center position-relative">
          <div className="col-12 col-sm-3 col-6 col-lg-2 col-xl-2 order-lg-1">
            <div className="brand">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="/">
                <img src={logo} height="36" alt="" />
              </a>
            </div>
          </div>

          <div className="col-12 col-sm-5 col-xm-12 col-lg-3 col-xl-3 order-lg-2">
            <div className="featured-ph mt-4 mt-sm-0">
              <a
                href="https://www.producthunt.com/posts/way-2?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-way-2"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=266248&theme=light"
                  alt="Way - Create custom landing pages that convert more visitors. | Product Hunt Embed"
                  style={{ width: 250, height: 54 }}
                  width="250"
                  height="54"
                />
              </a>
            </div>
          </div>

          <div className="col-sm-4 col-lg-2 col-xl-2 order-lg-4 d-none d-sm-block">
            <div className="header-btns">
              <a href={config.onboardingUrl}>
                <div className="btn-2">{t("Start Free")}</div>
              </a>
            </div>
          </div>

          <div className="col-sm-2 col-6 col-lg-4 offset-lg-1 col-xl-3 offset-xl-2 order-lg-3 position-static">
            <div className="main-navigation">
              <ul className="main-menu">
                <li className="menu-item ">
                  <a href="#features">{t("Features")}</a>
                </li>
                <li className="menu-item">
                  <a href="#pricing">{t("Pricing")}</a>
                </li>
                <li className="menu-item">
                  <a href={config.appUrl}>{t("Sign In")}</a>
                </li>
              </ul>
            </div>
            <div className="mobile-menu"></div>
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
