import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Subscribe from "./forms/subscribe"

function Footer() {
  const { t } = useTranslation()
  return (
    <div className="footer-section">
      <div className="container margin-decrese">
        <div className="row">
          <div className="text-center text-lg-left col-lg-6 col-12 col-margin">
            <span className="footer-title-sm">{t("Help menu")}</span>
            <div className="row">
              <div className="col-lg-12 col-12">
                <ul className="footer-list">
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href="#"
                      onClick={() => {
                        if (window.$crisp) {
                          window.$crisp.push(["do", "chat:open"])
                        }
                      }}
                    >
                      {t("Contact")}
                    </a>
                  </li>
                  <li>
                    <a href="https://help.way.live">{t("Help & Support")}</a>
                  </li>
                  <li>
                    <a href="https://ema.way.live/privacy">
                      {t("Privacy Policy")}
                    </a>
                  </li>
                  <li>
                    <a href="https://ema.way.live/terms">
                      {t("Terms & Conditions")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6 offset-xl-1 col-12 col-margin">
            <Subscribe />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
