import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import dragndrop from "../assets/images/about-dragndrop.png"
import templates from "../assets/images/about-templates.png"

function About() {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <section className="about-section-01">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-xl-5 d-flex align-items-center">
              <div className="about-left-content">
                <h2>{t("Drag & Drop Pages.")}</h2>
                <p>
                  {t(
                    "Create custom pages that convert more visitors. No coding required."
                  )}
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-5 col-xl-7">
              <div className="about-image-right">
                <img src={dragndrop} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-section-02">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-5 col-xl-7">
              <div className="about-image-left">
                <img src={templates} alt="" />
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-xl-5 d-flex align-items-center">
              <div className="about-right-content">
                <h2>{t("Tons of templates and components.")}</h2>
                <p>
                  {t(
                    "Templates and a lot of ready-to-use components to make your page beautiful and powerful."
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default About
