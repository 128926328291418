import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { useQueryParam } from "gatsby-query-params"

function AwcOnly({ children }) {
  const ref = useQueryParam("ref", null)
  return ref === "awc" ? children : null
}

function AwcCoupon() {
  return (
    <div className="coupon">
      <div>AWC50</div>
    </div>
  )
}

function AwcBanner() {
  const { t } = useTranslation()
  return (
    <AwcOnly>
      <div className="banner">
        <div className="container">
          <div className="inner">
            <div className="message">
              <h5>{t("Hello, Apps Without Code Students!")}</h5>
              <div>
                <span>{t("Glad you are here. Remember to use the code")}</span>
                <AwcCoupon />
                <span>{t("for 50% OFF For THREE Months")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AwcOnly>
  )
}

export default AwcBanner
