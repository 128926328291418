import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import config from "../../config/website"

function Benefit({ disabled, label }) {
  return (
    <li className={`list-group-item ${disabled ? "disable-opt" : ""}`}>
      {label}
    </li>
  )
}

export function Pricing() {
  const { t } = useTranslation()
  return (
    <section className="pricing-section" id="pricing">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-xl-7 col-md-9">
            <div className="section-title">
              <h2>{t("Simple pricing")}</h2>
              <p>
                {t("Free trial for")} <b>{t("14 days")}</b>, {t("then")}
              </p>
            </div>
          </div>
        </div>
        <div className="row no-gutters ">
          <div className="col-12 d-flex justify-content-center"></div>
        </div>
        <div className="custom-tab-wrapper monthly" id="pricing-tab">
          <div className="row mb-d-30 no-gutters">
            <div className="col-lg-6 mx-auto mb--30">
              <div className="pricing-card">
                <div className="card-head">
                  <div className="price">
                    <span className="currency">{t("currency")}</span>
                    <span className="value">{t("price")}</span>
                    <span className="interval">{t("/month")}</span>
                  </div>
                </div>
                <div className="pricing-list">
                  <ul>
                    <Benefit label={t("Unlimited Pages")} />
                    <Benefit label={t("Components")} />
                    <Benefit label={t("Templates")} />
                    <Benefit label={t("Export Leads")} />
                    <Benefit label={t("Free .way.live subdomain")} />
                    <Benefit label={t("Google Analytics Integration")} />
                    <Benefit label={t("Google Tag Manager Integration")} />
                    <Benefit label={t("Custom Domain (SSL included)")} />
                    <Benefit label={t("24/7 Customer Support")} />
                  </ul>
                  <a href={config.onboardingUrl} className="pricing-btn">
                    {t("Start Free Trial")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Pricing
